<template>
  <div>
    <b-row class="m-0">
      <b-col
        class="content-header"
        cols="12"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="content-header-search mr-1">
          <b-form-input
            id="searchPoolAccess"
            v-model="searchPoolAccess"
            placeholder="Rechercher un type d'accès"
            v-on:input="onFilterTextBoxChanged()"
            class="w-full"
          />
        </div>
        <div class="content-header-actions">
          <b-button
            variant="primary"
            @click="popupCreatePoolAccessTypes('')"
            class="d-flex px-1 mr-1"
          > 
            <feather-icon icon="PlusIcon" />
            <span class="d-none d-md-block">Nouveau</span> 
          </b-button>
          <b-dropdown class="bg-outline-primary btn-group-icon" variant="outline-primary">
            <template #button-content>
              <span class="material-icons-outlined m-0 p-0">settings</span>
            </template>
            <b-dropdown-item class="w-full" @click="exportDataCSV">Exporter en CSV</b-dropdown-item>
            <b-dropdown-item class="w-full" @click="exportDataXLS">Exporter en XLS</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <div class="content-grid">
      <div class="text-center flex-center" v-if="isLoadingPoolAccessTypesList">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          {{'Chargement en cours de la liste des types d\'accès...'}}
        </div> 
      </div>
      <ag-grid-vue v-else style="width: 100%; height: 100%;"
          class="ag-theme-material"
          :rowData="gridData"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          @rowClicked="onRowClicked">
      </ag-grid-vue>
    </div>
    <popup-pool-access-types ref="popupPoolAccessTypes"/>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
import { agGridLocaleFR } from "@/data/agGridLocaleFR"

import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'


import PopupPoolAccessTypes from "@/components/types/PopupPoolAccessTypes"


export default {
  components: {
    BButton,
    AgGridVue,
    PopupPoolAccessTypes
  },
  directives: {
    Ripple
  },
  data () {
    return {
      filter: false,
      sidebar:false,
      activeSidebarPoolAccess:false,
      gridOptions:{
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination:true,
        paginationPageSize:20,
      },
      gridApi: null,
      columnApi: null,
      searchPoolAccess: ''
    }
  },
  computed: {
    ...mapGetters([
      'poolAccessTypesList',
      'isLoadingPoolAccessTypesList',
      'isOpenSidebarPoolAccessType',
    ]),
    gridData () {
      return this.$store.getters.poolAccessTypesList
    }
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      { field: 'label', headerName: 'Nom', resizable:true,  sortable: true, minWidth: 160},
    ];
  },
  created(){
    this.fetchPoolAccessTypesList();
  },
  methods: {
    ...mapActions([ 
      'fetchPoolAccessTypesList',
      'deletePoolAccess'
    ]),

    // Gestion d'AgGrid
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridApi.getColumnDefs().forEach((column) => {
        allColumnIds.push(column.field);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
    },
    onRowClicked(event){
      this.popupCreatePoolAccessTypes(event.data.id)
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(
        this.searchPoolAccess
      );
    },
    togglePanelColumn(){
      if(!this.gridOptions.sideBar || this.gridOptions.sideBar== null ){
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Colonnes',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: 'columns',
        }
        this.filter=true;
      }else{
        this.gridOptions.sideBar= null
        this.filter=false;
      }
    },
    exportDataXLS(){
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV(){
      this.gridApi.exportDataAsCsv();
    },
    popupCreatePoolAccessTypes(id){
      this.$bvModal.show('popup-pool-access-types')
      this.$refs['popupPoolAccessTypes'].popupCreatePoolAccesssTypesEvent(id)
    },
  }
}
</script>
